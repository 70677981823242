import { render } from 'lit-html';

import heroImage from '../components/heroImage/heroImage';
import { widgetSelector } from '../constants';
import { HeroImageProps } from '../types/heroImageProps';

const renderHeroImage = (props: HeroImageProps): void => {
    const widget = document.querySelector(widgetSelector(props._.id));
    if (widget) {
        render(heroImage(props), widget);
    }
};

export default renderHeroImage;
