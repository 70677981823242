import { initTemplatePolyfill } from 'lit-html/polyfills/template_polyfill';

import { ToolbarItem } from '../services/inline/util';
import { HeroImageProps } from '../types/heroImageProps';
import HeroImage from '../heroImage';
import HeroImageWidget from '../types/heroImageWidget';

// Get template polyfill (only needed on IE).
initTemplatePolyfill();

window.BigCommerce = window.BigCommerce || {};

window.BigCommerce.initializeHeroImage = (props: HeroImageProps): HeroImageWidget => {
    return new HeroImage(props);
};
