import { ImageFit, renderImageVerticalAlignment } from '@bigcommerce/page-builder-sdk';

import { BackgroundType, HeroImageProps } from '../../../types/heroImageProps';

const renderBackgroundStyles = (props: HeroImageProps): string => {
    const { backgroundColor, backgroundType, imageUrl } = props;
    const backgroundImageStyle = `
        background-image: url(${imageUrl.src});
        background-size: ${props.imageFit === ImageFit.Fit ? 'contain' : 'cover'};
        background-position-x: ${props.imageAlignment.horizontal};
        background-position-y: ${renderImageVerticalAlignment(props.imageAlignment.vertical)};
        background-repeat: no-repeat;
    `;

    switch (backgroundType) {
        case BackgroundType.IMAGE:
            return backgroundImageStyle;
        case BackgroundType.COLOR:
            return `background-color: ${backgroundColor}`;
        default:
            return 'background-color: transparent';
    }
};

export default renderBackgroundStyles;
