import { HeroImageSize, ScreenSize } from '@bigcommerce/page-builder-sdk';

const RESPONSIVE_IMAGE_HEIGHT = {
    MOBILE: {
        small: '300px',
        medium: '300px',
        large: '300px',
    },
    TABLET: {
        small: '300px',
        medium: '500px',
        large: '650px',
    },
    DESKTOP: {
        small: '300px',
        medium: '500px',
        large: '650px',
    },
};

const renderImageHeight = (screenSize: ScreenSize, containerHeight: HeroImageSize): string => {
    switch (screenSize) {
        case ScreenSize.Mobile:
            return RESPONSIVE_IMAGE_HEIGHT.MOBILE[containerHeight];
        case ScreenSize.Tablet:
            return RESPONSIVE_IMAGE_HEIGHT.TABLET[containerHeight];
        case ScreenSize.Desktop:
            return RESPONSIVE_IMAGE_HEIGHT.DESKTOP[containerHeight];
        default:
            return '300px';
    }
};

export default renderImageHeight;
