import { css } from 'goober';
import { handleVisibility } from '@bigcommerce/page-builder-sdk';

import { HeroImageProps, TextStyle } from '../../types/heroImageProps';

const getTitleStyles = (titleStyle: TextStyle, props: HeroImageProps): string => {
    switch (titleStyle) {
        case TextStyle.DEFAULT:
            return `
                font-size: 32px;
            
                @media screen and (min-width: 801px) {
                    font-size: 36px;
                    font-weight: 500;
                }
            `;
        case TextStyle.CUSTOM:
            return `
                font-family: ${props.titleFontFamily};
                font-size: ${props.titleFontSize.value}${props.titleFontSize.type};
                font-weight: ${props.titleFontWeight};
            `;
        default:
            return '';
    }
};

const getDescriptionStyles = (titleStyle: TextStyle, props: HeroImageProps): string => {
    switch (titleStyle) {
        case TextStyle.DEFAULT:
            return `
                font-size: 18px;
                
                @media screen and (min-width: 801px) {
                    font-size: 21px;
                }
            `;
        case TextStyle.CUSTOM:
            return `
                font-family: ${props.descriptionFontFamily};
                font-size: ${props.descriptionFontSize.value}${props.descriptionFontSize.type};
                font-weight: ${props.descriptionFontWeight};
            `;
        default:
            return '';
    }
};

export const heroTitleStyles = (props: HeroImageProps): string => {
    // prettier-ignore
    return css`
        display: flex;
        align-items: center;
        ${handleVisibility(props.titleVisible)};
        ${getTitleStyles(props.titleStyle, props)};
        min-height: 48px;
        color: ${props.mobileTextColor};

        @media screen and (min-width: 801px) {
            min-height: 50px;
            color: ${props.textColor};
        }
    `;
};

export const heroDescriptionStyles = (props: HeroImageProps): string => {
    // prettier-ignore
    return css`
        ${handleVisibility(props.descriptionVisible)};
        ${getDescriptionStyles(props.descriptionStyle, props)};
        margin-top: 8px;
        min-height: 27px;
        color: ${props.mobileTextColor};

        @media screen and (min-width: 801px) {
            color: ${props.textColor};
            min-height: 31px;
            margin-top: 8px;
        }
    `;
};
