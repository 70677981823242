import { css } from 'goober';

import isEditModeDisabled from '../../lib/isEditModeDisabled';
import { HeroImageProps } from '../../types/heroImageProps';

const contentEditableStyling = (props: HeroImageProps): string => {
    // prettier-ignore
    return css`
        p {
            margin: 0;
        }

        ${!isEditModeDisabled(props) && `
            min-width: 1rem;
            cursor: text;

            &:focus, &:hover {
                outline: 1px dashed #3C64F4;
            }
        `}
    `;
};

export default contentEditableStyling;
