import { HeroImageProps } from '../types/heroImageProps';
import isEditModeDisabled from '../lib/isEditModeDisabled';

function handleLinkRedirect(event: MouseEvent, props: HeroImageProps): void {
    const { bannerLink } = props;
    const hasLink = bannerLink && bannerLink.trim();

    // checking editMode is off or undefined
    if (isEditModeDisabled(props) && hasLink) {
        event.preventDefault();
        event.stopPropagation();

        const bannerUrlParts = bannerLink.split('/');
        const [protocol] = bannerUrlParts;

        // check if link is not properly formatted
        if (protocol !== '' && !protocol.includes('http')) {
            bannerUrlParts.unshift('/');
        }

        const newBannerUrl = bannerUrlParts.join('/');
        window.open(newBannerUrl, '_top');
    }
}

export default handleLinkRedirect;
