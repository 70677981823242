import { css } from 'goober';
import { handleVisibility } from '@bigcommerce/page-builder-sdk';

import renderBorderRadius from '../../lib/widgetSchema/borderRadius/borderRadius';
import { HeroImageProps, TextStyle } from '../../types/heroImageProps';

const CUSTOM_FONT_STYLES = [TextStyle.DEFAULT, TextStyle.CUSTOM];

const heroButtonStyles = (props: HeroImageProps): string => {
    const { buttonFont, buttonFontFamily } = props;
    const isCustomFont = CUSTOM_FONT_STYLES.includes(buttonFont) || !buttonFont;

    // prettier-ignore
    return css`
        background: ${props.mobileButtonColor};
        border: none;
        color: ${props.mobileButtonTextColor};
        cursor: pointer;
        ${isCustomFont ? 'font-size: 18px' : ''};
        ${handleVisibility(props.buttonVisible)};
        margin-top: 24px;
        outline: none;
        padding: 8px 24px;
        pointer-events: auto;
        text-decoration: none;
        ${renderBorderRadius(props.buttonStyle)};
        font-family: ${isCustomFont && buttonFontFamily ? buttonFontFamily : 'inherit'};

        @media screen and (min-width: 801px) {
            background: ${props.buttonColor};
            color: ${props.buttonTextColor};
            ${isCustomFont ? 'font-size: 21px' : ''};
        }
    `;
};

export default heroButtonStyles;
