import { Alignment, ButtonStyle, HeroImageSize, ImageFit, ImageUrl, Visibility } from '@bigcommerce/page-builder-sdk';

import { ImageParallaxEnabled } from '../lib/widgetSchema/imageParallax/imageParallax';

export interface PreviewState {
    editMode?: boolean;
}

export interface PageBuilderData {
    previewState?: PreviewState;
}

interface ProtectedNamespace {
    id: string;
    pageBuilderData: PageBuilderData;
}

export enum BackgroundType {
    IMAGE = 'image',
    COLOR = 'color',
    VIDEO = 'video',
}

export enum TextStyle {
    BODY_TEXT = 'global_typography_body_text',
    CUSTOM = 'custom',
    DEFAULT = 'default',
    HEADING_1 = 'global_typography_h1',
    HEADING_2 = 'global_typography_h2',
    HEADING_3 = 'global_typography_h3',
    HEADING_4 = 'global_typography_h4',
    HEADING_5 = 'global_typography_h5',
    HEADING_6 = 'global_typography_h6',
    SECONDARY_TEXT = 'global_typography_secondary_text',
}

interface FontSize {
    value: number;
    type: string;
}

export interface HeroImageProps {
    _: ProtectedNamespace;
    backgroundType: BackgroundType;
    backgroundColor: string;
    bannerLink: string;
    buttonColor: string;
    buttonStyle: ButtonStyle;
    buttonFont?: TextStyle;
    buttonText: string;
    buttonTextColor: string;
    buttonFontFamily?: string;
    buttonVisible: Visibility;
    containerHeight: HeroImageSize;
    contentAlignment: Alignment;
    descriptionFontFamily: string;
    descriptionFontWeight: string;
    descriptionFontSize: FontSize;
    descriptionStyle: TextStyle;
    descriptionVisible: Visibility;
    imageAlignment: Alignment;
    imageFit: ImageFit;
    imageOpacity: number;
    imageParallax: ImageParallaxEnabled;
    imageUrl: ImageUrl;
    mobileButtonColor: string;
    mobileButtonTextColor: string;
    mobileTextColor: string;
    subtitle: string;
    textAlign: string;
    textColor: string;
    title: string;
    titleFontFamily: string;
    titleFontWeight: string;
    titleFontSize: FontSize;
    titleStyle: TextStyle;
    titleVisible: Visibility;
    videoUrl: string;
}
