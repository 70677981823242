import { HorizontalAlign, ScreenSize } from '@bigcommerce/page-builder-sdk';

const renderContentContainerSize = (screenSize: ScreenSize, contentAlignment: HorizontalAlign): string => {
    if (screenSize === ScreenSize.Mobile) {
        return '100%';
    }

    return contentAlignment !== HorizontalAlign.Center ? '50%' : '66.67%';
};

export default renderContentContainerSize;
