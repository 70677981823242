import { ScreenSize } from '@bigcommerce/page-builder-sdk';

export enum ImageParallaxEnabled {
    On = 'on',
    Off = 'off',
}

const renderImageParallax = (screenSize: ScreenSize, imageParallax: ImageParallaxEnabled): string => {
    if (!imageParallax || imageParallax === ImageParallaxEnabled.Off) {
        return '';
    }

    const backgroundAttachment = screenSize === ScreenSize.Mobile ? 'scroll' : 'fixed';

    return `
        &:after {
            background-attachment: ${backgroundAttachment};
        }
    `;
};

export default renderImageParallax;
