import { html, TemplateResult } from 'lit-html';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';

import heroButton from '../heroImageButton/heroImageButton';
import globalStyleClass from '../../services/globalStyle/globalStyle';
import contentEditableStyling from '../../services/inline/styles';
import { contentEditable } from '../../services/inline/util';
import { HeroImageProps } from '../../types/heroImageProps';

import { heroTitleStyles, heroDescriptionStyles } from './styles';

const heroImageContent = (props: HeroImageProps): TemplateResult => {
    // prettier-ignore
    const { title, subtitle, _: { id } } = props;

    return html`
        ${unsafeHTML(`
            <div
                class="
                    ${heroTitleStyles(props)}
                    ${contentEditable(id, 'title')}
                    ${contentEditableStyling(props)}
                    ${globalStyleClass(props.titleStyle)}
                "
                data-test-id="hero-image-title"
            >
                ${title}
            </div>
        `)}
        ${unsafeHTML(`
            <div
                class="
                    ${heroDescriptionStyles(props)}
                    ${contentEditable(id, 'subtitle')}
                    ${contentEditableStyling(props)}
                    ${globalStyleClass(props.descriptionStyle)}
                "
                data-test-id="hero-image-subtitle"
            >
                ${subtitle}
            </div>
        `)}
        ${heroButton(props)}
    `;
};

export default heroImageContent;
