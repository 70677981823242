import { html, TemplateResult } from 'lit-html';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';

import handleLinkRedirect from '../../services/handleLinkRedirect';
import { HeroImageProps, TextStyle } from '../../types/heroImageProps';
import contentEditableStyling from '../../services/inline/styles';
import { contentEditable } from '../../services/inline/util';
import globalStyleClass from '../../services/globalStyle/globalStyle';

import heroButtonStyles from './styles';

const heroButton = (props: HeroImageProps): TemplateResult => {
    // prettier-ignore
    const { buttonText, _: { id } } = props;

    return html`
        <div
            class="
                ${globalStyleClass(props.buttonFont || TextStyle.DEFAULT)}
                ${heroButtonStyles(props)}
            "
            role="button"
            @click="${(event: MouseEvent): void => handleLinkRedirect(event, props)}"
            data-test-id="hero-image-button"
        >
            ${unsafeHTML(`
                <div
                    class="${contentEditable(id, 'buttonText')} ${contentEditableStyling(props)}"
                >
                    ${buttonText}
                </div>
            `)}
        </div>
    `;
};

export default heroButton;
