import renderHeroImage from './services/heroImageRenderer';
import { BackgroundType, HeroImageProps } from './types/heroImageProps';
import { widgetSelector } from './constants';
import HeroImageWidget from './types/heroImageWidget';

export default class HeroImage implements HeroImageWidget {
    public readonly uuid: string;

    private props: HeroImageProps;

    public constructor(props: HeroImageProps) {
        this.props = props;
        this.uuid = this.props._.id;
    }

    public getProps = (): HeroImageProps => this.props;

    public setProps = (props: HeroImageProps): void => {
        this.props = props;
    };

    public render = (): void => {
        renderHeroImage(this.getProps());

        // Firefox Fix - will not autoplay video on initial load due to audio not being muted
        if (this.props.backgroundType === BackgroundType.VIDEO) {
            this.muteVideo();
        }
    };

    public setEditMode = (editMode: boolean): void => {
        this.props._.pageBuilderData.previewState.editMode = editMode;
    };

    public muteVideo = (): void => {
        const element = document.querySelector(`${widgetSelector(this.uuid)} video`);
        if (!element) {
            return;
        }

        const videoElement = element as HTMLVideoElement;
        videoElement.muted = true;
    };
}
